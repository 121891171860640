import React, { Component } from "react";
import { Container, Button, Row, Col} from "react-bootstrap";
import "../pages/ThisYearPage.css"
import VistaPromo from "../images/VistaPromo2.jpg";
import ToerPromo from "../images/VistaPromo3.jpg";
import BrightlandsPromo from "../images/BrightlandsPromo2.jpg"
import BrightlandsRoute from "../images/BrightlandsPromo3.jpg"
import CoronaInformatie from "../images/CoronaInformatie.jpg"


class ThisYearPage extends Component {
    render() {
        return(
            <React.Fragment>
                <section className="ty-s0" >
                    <Container fluid className="call-to-signup">
                        <h1>VISTALAN 2021-2022</h1>
                        <Button disabled>Inschrijven</Button>
                    </Container>
                    <div className="spacer ty-s0-layer"/>
                </section>

                <section className="ty-s1">
                    <Container fluid>
                        <Row className="ty-s1-r0">
                            <h1>Alles VISTALAN</h1>
                        </Row>
                        <Row className="ty-s1-r1">
                            <Col lg="auto"><img src={VistaPromo} alt=""></img></Col>
                            <Col lg>
                                <p>De ticketverkoop zal in de eerste week van december beginnen en er zijn +/- 80 plaatsen beschikbaar, we zijn nog in overleg om voor (TBD) een verkort rooster te hebben zodat iedereen een mogelijkheid heeft om op tijd te komen. Als je eenmaal een ticket hebt kun je op een later moment je aanmelden voor de verschillende toernooien met de unieke code die op jouw ticket staat.</p>
                                <p style={{margin: "0"}}>Dit moet je weten:</p>
                                <p style={{margin: "0"}}>Wat: De grootste LAN-party in Zuid Limburg exclusief voor VISTA leerlingen (+/- 80 deelnemers)</p>
                                <p style={{margin: "0"}}>TBD</p>
                                <p style={{margin: "0"}}>Waar: Brightlands Campus (adres) Heerlen, 5e verdieping</p>
                            </Col>
                        </Row>
                        <Row className="ty-s1-r2">
                            <Col lg>
                                <p>De <span>VISTALAN</span> is dit jaar op (TBD). Ingeschreven studenten kunnen zich vanaf 11:00 melden bij de goedereningang van de Brightlands Campus waar een goederen lift hen in staat stelt om hun PC makkelijk naar de 5e verdieping te brengen. De eerste dag zullen verschillende toernooien worden gehouden, rond 20:00 zal het eten verzorgd worden en door de avond heen zullen er snacks zijn en additionele activiteiten om te doen. Studenten die willen roken kunnen dat doen aan de buiten kan van de toren. Studenten die tijdens de LAN eerder naar huis willen kunnen dat ook doen. Elke ingeschreven student krijgt bij het eerste keer melden een badge die ze kunnen tonen aan de security waarmee ze het Brightlands gebouw in en uit kunnen komen. </p>
                                <p>Of je super competetief jezelf wil bewijzegen tegen je medestudenten of dat je gewoon 2 dagen op een relaxte manier wil gamen met vrienden in een mooie omgeving. De Vista LAN is er voor jou!</p>
                            </Col>
                            <Col lg="auto"><img src={ToerPromo} alt=""></img></Col>
                        </Row>
                        <Row className="ty-s1-r3">
                            <Col lg>
                                <a href="https://vistacollege.nl/" target="_blank" rel="noreferrer">
                                    <Button className="b1">Vista College</Button>
                                </a>
                            </Col>
                            <Col lg>

                            </Col>
                            <Col lg>
                                <Button className="b2" disabled>Toernooien</Button
                            ></Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer2"/>

                <section className="ty-s2">
                <Container fluid>
                        <Row className="ty-s2-r0">
                            <h1>Brightlands Smart Service Campus</h1>
                        </Row>
                        <Row className="ty-s2-r1">
                            <Col lg="auto"><img src={BrightlandsPromo} alt=""></img></Col>
                            <Col lg>
                                <p>Brightlands Smart Services Campus is onderdeel van Brightlands, een open innovatie community in Limburg met bijna 29.000 innovatieve ondernemers, onderzoekers en studenten. Elke dag werken zij aan doorbraken op het gebied van duurzaamheid, gezondheid en digitalisering. Op Brightlands Smart Services Campus in Heerlen zorgen zij samen dat kennis op het gebied van data science leidt tot nieuwe slimme digitale diensten die de kwaliteit van het leven verbeteren.</p>
                                <p>Brightlands Smart Services Campus is de plek waar digitale start-ups, bedrijven, overheden, onderzoeksinstituten en studenten samen leren, innoveren en groeien. De campus bestaat uit een community van experts die werkzaam zijn bij 80 bedrijven en instellingen, start-ups en grote organisaties als APG, CBS, Accenture, Conclusion, de Nederlandse Politie en ASR. Samen werken ze aan innovaties in diensten die het leven veiliger, gezonder en duurzamer maken, daarbij ondersteund door jong digitaal talent van campusgerelateerde kennisinstellingen</p>
                            </Col>
                        </Row>
                        <Row className="ty-s2-r2">
                            <Col lg>
                                <p>De Brightlands Smart Services Campus is makkelijk te bereiken via de snelweg. Studenten die zich hebben ingeschreven voor de <span>VISTALAN</span> kunnen hun spullen in de morgen naar de dienstingang van de High tech campus rijden waarna ze de lift en de goederen karren kunnen gebruiken om hun PC makkelijk op de plek te krijgen</p>
                            </Col>
                            <Col lg="auto"><img src={BrightlandsRoute} alt=""></img></Col>
                        </Row>
                        <Row className="ty-s2-r3">
                            <Col lg>
                                <a href="https://www.brightlands.com/" target="_blank" rel="noreferrer">
                                    <Button className="b1">Brightlands</Button>
                                </a>
                            </Col>
                            <Col lg></Col>
                            <Col lg>
                                <a href="https://goo.gl/maps/3Gqdjp5xk1vq6fYU9" target="_blank" rel="noreferrer">
                                    <Button className="b2">Route</Button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer3"/>

                <section className="ty-s3">
                <Container fluid>
                        <Row className="ty-s3-r0">
                            <h1>Overige informatie</h1>
                        </Row>
                        <Row className="ty-s3-r1">
                            <Col lg="auto"><img src={CoronaInformatie} alt=""></img></Col>
                            <Col lg>
                                <p>Vanwege Covid 19 moeten alle studenten een negatief testbewijs of hun coronapas laten zien bij binnenkomst. Hiermee kunnen ze bewijzen of ze getest, gevaccineerd, of genezen zijn. Uiteraard houden wij nauwgezet de nieuwe regelgeving met betrekking tot Corona in de gaten. Op het moment dat de regelgeving verscherpt wordt, kan het zijn dat de VISTALAN aangepast wordt, of in het ergste geval verschoven naar een andere datum. Als de VISTALAN naar een andere datum gaat, behouden de op dat moment ingeschreven studenten hun plek en worden ze direct geinformeerd.</p>
                            </Col>
                        </Row>
                        <Row className="ty-s3-r2">

                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
export default ThisYearPage;