import React, { Component } from "react";
import { Nav, Navbar as BNavBar, Container} from 'react-bootstrap';
import { NavLink } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css'
import '../navigation/Navbar.css'

import VistaLanLogo from '../images/LogoLichtblauw.png'

class Navbar extends Component {
    render() {
        return(
        <React.Fragment>
            <BNavBar expand="md" className="color-nav" variant="dark">
                <Container fluid>
                    <BNavBar.Brand>
                        <NavLink to="/" className='text-link'>
                            <img src={VistaLanLogo} alt="the vistalan logo"/>
                        <BNavBar.Text className="logoname">
                            Vistalan
                        </BNavBar.Text>
                        </NavLink>    
                    </BNavBar.Brand>                
                    <BNavBar.Toggle aria-controls="basic-navbar-nav"/>
                    <BNavBar.Collapse className="justify-content-end" id="basic-navbar-nav">
                        <Nav className="align-items-center">
                            <Nav.Link as= {NavLink} to="/ditjaar" className='text-link' activeStyle={{color: 'white'}}>
                                Dit jaar
                            </Nav.Link>
                            <Nav.Link as= {NavLink} to="/sponsoren" className='text-link' activeStyle={{color: 'white'}}>
                                Sponsoren
                            </Nav.Link>
                            <Nav.Link as= {NavLink} to="/geschiedenis" className='text-link'activeStyle={{color: 'white'}}>
                                Geschiedenis
                            </Nav.Link>
                            <Nav.Link as= {NavLink} to="/contact" className='text-link'activeStyle={{color: 'white'}}>
                                Contact
                            </Nav.Link>
                        </Nav>
                    </BNavBar.Collapse>
                </Container>
            </BNavBar>
        </React.Fragment>
        )
    }
}
export default Navbar;