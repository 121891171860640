import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faTwitch, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

import '../navigation/Footer.css'

class Footer extends Component {
    render() {
        return(
            <React.Fragment>
                <footer>
                    <Container fluid>
                        <Row>
                            <Col className="MiddleCol Mob" md>
                                Vistalan
                            </Col>
                            <Col className="LeftCol" md>
                                <a href="https://www.twitch.tv/vistalan" rel="noreferrer" target="_blank" className="foot-icon" id="Twitch">
                                    <FontAwesomeIcon icon={faTwitch} fixedWidth></FontAwesomeIcon>
                                </a>
                                <a href="https://www.facebook.com/VISTAcollege.ict/" rel="noreferrer" target="_blank" className="foot-icon" id="Facebook">
                                    <FontAwesomeIcon icon={faFacebook} fixedWidth></FontAwesomeIcon>
                                </a>
                                <a href="https://www.instagram.com/vistacollege_ict/?hl=en" rel="noreferrer" target="_blank" className="foot-icon" id="Instagram">
                                    <FontAwesomeIcon icon={faInstagram} fixedWidth></FontAwesomeIcon>
                                </a>
                                <a href="https://www.youtube.com/channel/UCzosI3K0hrW6j7ZdJbzK_OQ" rel="noreferrer" target="_blank" className="foot-icon" id="Youtube">
                                    <FontAwesomeIcon icon={faYoutube} fixedWidth></FontAwesomeIcon>
                                </a>
                            </Col>
                            <Col className="MiddleCol Desk" md>
                                Vistalan
                            </Col>
                            <Col className="RightCol" md>
                                <Link className="foot-link" to="/contact">Stuur feedback</Link> <Link className="foot-link" to="/">Privacy en voorwaarden</Link>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>          
        )
    }
}
export default Footer;