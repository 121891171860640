import Navbar from './navigation/Navbar.js'
import Footer from './navigation/Footer.js';
import LandingPage from './pages/LandingPage';
import ThisYearPage from './pages/ThisYearPage.js';
import SponsorsPage from './pages/SponsorsPage.js';
import PackagesPage from './pages/PackagesPage.js';
import HistoryPage from './pages/HistoryPage.js';
import ContactPage from './pages/ContactPage.js';
import ScrollToTop from "./classes/ScrollToTop";
import './App.css';
import { BrowserRouter as Router, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
        <Navbar/>
        <Route path="/" exact render={() => {
          return <LandingPage/>
        }} />
        <Route path="/ditjaar" exact render={() => {
          return <ThisYearPage/>
        }} />
        <Route path="/sponsoren" exact render={() => {
          return <SponsorsPage/>
        }} />
        <Route path="/sponsoren/pakketten" exact render={() => {
          return <PackagesPage/>
        }} />
        <Route path="/geschiedenis" exact render={() => {
          return <HistoryPage/>
        }} />
        <Route path="/contact" exact render={() => {
          return <ContactPage/>
        }} />   
        <Footer/>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
