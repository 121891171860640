import React, { Component } from "react";
import { Container, Button, Row, Col, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import "../pages/SponsorsPage.css";
import AH from "../images/AhSponsor.jpg";
import Brightlands from "../images/BrightlandsSponsor.jpg";
import Vista from "../images/VistaSponsor.jpg";


class SponsorsPage extends Component {
    render() {
        return(
            <React.Fragment>
                <section className="sp-s0" >
                    <Container fluid className="call-to-sponsor">
                        <h1>VISTALAN Sponsoren</h1>
                        <Link to="/sponsoren/pakketten"> 
                            <Button className="info-click" size="lg">Word een sponsor</Button>
                        </Link>
                    </Container>
                    <div className="spacer sp-s0-layer"/>
                </section>

                <section className="sp-s1">
                    <Container fluid>
                        <Row className="sp-s1-r0">
                            <h1>Sponsoren</h1>
                        </Row>
                        <Row className="sp-s1-r1">
                            <Col lg>
                                <p>De <span>VISTALAN</span> is een vrijwillige onderneming en sponsoring is nodig om de LAN te kunnen draaien. Deze verschillende bedrijven en partijen dragen dit jaar mee aan het mogelijk maken van de <span>VISTALAN</span>. We zijn hun zeer dankbaar voor hun steun en laten ons dit mooie evenement organiseren.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer2"/>

                <section className="sp-s2">
                <Container fluid=''>
                        <Row className="sp-s2-r0" xs={1} md={2} xl={3}>
                            <Col xl>
                                <Card border className="card-sponsor">
                                    <Card.Img variant="top" src={Vista}/>
                                    <Card.Body>
                                        <Card.Title>VISTA College</Card.Title>
                                        <Card.Text>
                                        <a href="https://vistacollege.nl/" target="_blank" rel="noreferrer">VISTA COLLEGE</a>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-sponsor">
                                    <Card.Img variant="top" src={Brightlands} />
                                    <Card.Body>
                                        <Card.Title>Brightlands Campus Heerlen</Card.Title>
                                        <Card.Text>
                                        <a href="https://www.brightlands.com/en" target="_blank" rel="noreferrer">BRIGHTLANDS</a>                                        
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-sponsor">
                                    <Card.Img variant="top" src={AH} />
                                    <Card.Body>
                                        <Card.Title>Albert Heijn Schandelerboord</Card.Title>
                                        <Card.Text>
                                        <a href="https://www.ah.nl/winkel/albert-heijn/heerlen/schandelerboord/1396" target="_blank" rel="noreferrer">ALBERT HEIJN</a>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer3"/>

                <section className="sp-s3">
                <Container fluid>
                        <Row className="sp-s3-r0">
                            <h1>Bedankt namens alle betrokkenen van de VISTALAN!</h1>
                        </Row>
                        <Row className="sp-s3-r1">
                            <Col lg></Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
export default SponsorsPage;