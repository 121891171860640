import {Button} from "react-bootstrap";
import React, { Component } from "react";
import "../pages/PackagesPage.css";

const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`} target="_blank" rel="noreferrer">{children}</a>;
  };


class PackagesPage extends Component {
    render() {
        return(
            <React.Fragment>
                <div className="maincontainer">
                    <section>
                        <div class="container py-5">
                            
                            <header class="text-center mb-5 text-white">
                            <div class="row headpiece">
                                <div class="col-lg-8 mx-auto">
                                <h1>VISTALAN Sponsorpakketten </h1>
                                <p>Wil je sponsor worden? Fantastisch! Er zijn 3 pakketten die wij aanbieden. De <span>VISTALAN</span> is een vrijwillige onderneming en sponsoring is nodig om de LAN te kunnen draaien. Kijk hieronder voor de 3 pakketten die we aanbieden. Staat daar niks bij dat bij je past maar wil je toch meehelpen? Neem dan contact met ons op via dit email address: <u>p.derix@vistacollege.nl</u></p>
                                </div>
                            </div>
                            </header>
                        
                            <div class="row text-center align-items-end">
                            
                            <div class="col-lg-4 mb-5 mb-lg-0">
                                <div class="bg-white p-5 rounded-lg shadow bbox">
                                <h1 class="h6 text-uppercase font-weight-bold mb-4">Bronze Tier</h1>
                                <h2 class="h1 font-weight-bold">€250</h2>
                                <div class="custom-separator my-4 mx-auto"></div>
                                <ul class="list-unstyled my-5 text-small text-left">
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Logo en naam vermelding op de website</li>
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Gebruik van banners en ander aangeleverd promotiemateriaal op de <span>VISTALAN</span> dagen</li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>Regelmatige vermelding van bedrijfsnaam op sociale media tijdens het event zelf</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>1 reclame moment per <span>VISTALAN</span> dag waar de LAN Hosts (Paul en Connor) het bedrijf aanprijzen</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>1 toernooi wordt vernoemd naar de sponsor</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>Naam bedrijf wordt ook vermeld bij de prijsuitreiking</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>Wildcard: heeft uw bedrijf een eigen idee om waar te maken tijdens ons evenement dan is daar een mogelijk voor! (Een kleine informatie booth, goodybags enz. veel ideeën zijn mogelijk)</del>
                                    </li>
                                </ul>
                                <Mailto email="p.derix@vistacollege.nl" subject="VISTALAN Gold Tier" body=""><Button className="mailbutton">Bronze Tier</Button></Mailto>
                                </div>
                            </div>
                            
                            <div class="col-lg-4 mb-5 mb-lg-0">
                                <div class="bg-white p-5 rounded-lg shadow bbox">
                                <h1 class="h6 text-uppercase font-weight-bold mb-4">Silver Tier</h1>
                                <h2 class="h1 font-weight-bold">€500</h2>
                                <div class="custom-separator my-4 mx-auto"></div>
                                <ul class="list-unstyled my-5 text-small text-left">
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Logo en naam vermelding op de website</li>
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Gebruik van banners en ander aangeleverd promotiemateriaal op de <span>VISTALAN</span> dagen</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>Regelmatige vermelding van bedrijfsnaam op sociale media tijdens het event zelf</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>1 reclame moment per <span>VISTALAN</span> dag waar de LAN Hosts (Paul en Connor) het bedrijf aanprijzen</li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>1 toernooi wordt vernoemd naar de sponsor</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>Naam bedrijf wordt ook vermeld bij de prijsuitreiking</del>
                                    </li>
                                    <li class="mb-3 text-muted">
                                    <i class="fa fa-times mr-2"></i>
                                    <del>Wildcard: heeft uw bedrijf een eigen idee om waar te maken tijdens ons evenement dan is daar een mogelijk voor! (Een kleine informatie booth, goodybags enz. veel ideeën zijn mogelijk)</del>
                                    </li>
                                </ul>
                                <Mailto email="p.derix@vistacollege.nl" subject="VISTALAN Gold Tier" body=""><Button className="mailbutton">Silver Tier</Button></Mailto>
                                </div>
                            </div>
                            
                            <div class="col-lg-4">
                                <div class="bg-white p-5 rounded-lg shadow bbox">
                                <h1 class="h6 text-uppercase font-weight-bold mb-4">Gold Tier</h1>
                                <h2 class="h1 font-weight-bold">€750</h2>
                                <div class="custom-separator my-4 mx-auto"></div>
                                <ul class="list-unstyled my-5 text-small text-left">
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Logo en naam vermelding op de website</li>
                                    <li class="mb-3">
                                    <i class="fa fa-check mr-2 text-primary"></i> Gebruik van banners en ander aangeleverd promotiemateriaal op de <span>VISTALAN</span> dagen</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>Regelmatige vermelding van bedrijfsnaam op sociale media tijdens het event zelf</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>1 reclame moment per <span>VISTALAN</span> dag waar de LAN Hosts (Paul en Connor) het bedrijf aanprijzen</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>1 toernooi wordt vernoemd naar de sponsor</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>Naam bedrijf wordt ook vermeld bij de prijsuitreiking</li>
                                    <li class="mb-3">
                                    <i class="fa fa-times mr-2"></i>Wildcard: heeft uw bedrijf een eigen idee om waar te maken tijdens ons evenement dan is daar een mogelijk voor! (Een kleine informatie booth, goodybags enz. veel ideeën zijn mogelijk)</li>
                                </ul>
                                    <Mailto email="p.derix@vistacollege.nl" subject="VISTALAN Gold Tier" body=""><Button className="mailbutton">Gold Tier</Button></Mailto>
                                </div>
                            </div>
                            
                            </div>
                        </div>
                        </section>
                    </div>       
            </React.Fragment>
        )
    }
}
export default PackagesPage;


