import React, { Component } from "react";
import { Container, Button, Row, Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../pages/LandingPage.css";
import Promo1 from "../images/VistaPromo1.png";
import Promo2 from "../images/BrightlandsPromo1.png";
import Promo3 from "../images/SponsorPromo1.png"

class LandingPage extends Component {
    render() {
        return(
            <React.Fragment>
                <section className="section-head" >
                    <Container fluid className="call-to-action">
                        <h1>VISTALAN 2021-2022</h1>
                        <h3>TBD</h3>
                        <h3>Brightlands Campus Heerlen</h3>
                        <Button className='sign-up' size="lg" disabled>Inschrijven</Button>
                        <Link to="/ditjaar">
                            <Button className="info-click" size="lg">Info</Button>
                        </Link>
                    </Container>
                    <div className="spacer layer1"/>
                </section>

                <section className="section-one">
                    <Container fluid>
                    <Row>
                        <Col lg="auto" className="section-one-image">
                            <img src={Promo1} alt="missing png"/>
                        </Col>
                        <Col lg className="section-one-text">
                            <h1>VISTALAN</h1>
                            <p>Het is 2021 en we zijn terug! Na een succesvolle LAN in 2019 is het tijd om weer een nieuwe <span>VISTALAN</span> te organiseren nu de lockdowns voorbij zijn. Dit jaar willen we het nog grootser aanpakken en hebben we een toplocatie geregeld: het Brightlands gebouw in Heerlen. Met een nieuwe toplocatie in het Brightlands gebouw in Heerlen en met ruimte voor meer gamers is dit hét jaar van de <span>VISTALAN</span>.</p>
                            <p>Schrijf je in voor de <span>VISTALAN</span> en bewijs jezelf tegen je medestudenten! Win prijzen en vooral het recht om jezelf de beste gamer te noemen van heel VISTA college! Binnenkort kun jij op de 5e verdieping van de Brightlands Campus jezelf bewijzen tegen je medestudenten en daarmee prijzen winnen; maar vooral natuurlijk voor het recht om jezelf de beste gamer te noemen van heel Vista college!</p>
                        </Col>
                    </Row>
                    </Container>
                </section>

                <div className="spacer layer2"/>

                <section className="section-two">
                <Container fluid>
                    <Row>
                        <Col lg className="section-two-text">
                            <h1>BRIGHTLANDS TECH CAMPUS</h1>
                            <p>Gelegen in het centrum van Heerlen domineert de Brightlands High Tech campus samen met het APG-gebouw ernaast de skyline van Heerlen. Op deze plek proberen entrepreneurs, studenten en onderzoekers een betere toekomst te creëren met de kracht van technologie. Maar in binnenkort is het tevens de plek waar studenten van Vista college strijden voor de eerste plek op de <span>VISTALAN</span>!</p>
                            <p>De brightlands high tech campus is makkelijk te bereiken via de snelweg. Studenten die zich hebben ingeschreven voor de <span>VISTALAN</span> kunnen hun spullen in de morgen naar de dienstingang van de High tech campus rijden waarna ze de lift en de goederen karren kunnen gebruiken om hun PC makkelijk op de plek te krijgen</p>
                        </Col>

                        <Col lg="auto" className="section-two-image">
                            <img src={Promo2} alt=""></img>
                        </Col>
                    </Row>
                    </Container>
                </section>

                <div className="spacer layer3"/>

                <section className="section-three">
                    <Container fluid>
                        <Row>
                            <Col lg="auto" className="section-one-image">
                                <img src={Promo3} alt=""></img>
                            </Col>
                            <Col lg className="section-three-text">
                                <h1>SPONSORS</h1>
                                <p>Onze sponsoren zijn: VISTA COLLEGE HEERLEN, ALBERT HEIJN SCHANDELERBOORD, en Brightlands. Dankzij hun steun is deze <span>VISTALAN</span> een realiteit.</p>
                                <p>Wil jij ook een sponsor worden van de <span>VISTALAN</span>? Klik dan <Link to="/sponsoren/pakketten">HIER</Link> en kijk welke 3 pakketten wij aanbieden</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
export default LandingPage;