import React, { Component } from "react";
import { Container, Button, Row, Col, Card} from "react-bootstrap";
import "../pages/HistoryPage.css"
import Soon from "../images/ComingSoon.jpg"

class HistoryPage extends Component {
    render() {
        return(
            <React.Fragment>
                <section className="hp-s0" >
                    <Container fluid className="call-to-nothing">
                        <h1>VISTALAN geschiedenis</h1>
                        <Button></Button>
                    </Container>
                    <div className="spacer hp-s0-layer"/>
                </section>

                <section className="hp-s1">
                    <Container fluid>
                        <Row className="hp-s1-r0">
                            <h1>voorgaande jaren</h1>
                        </Row>
                        <Row className="hp-s1-r1" xs={1} md={2} xl={3}>
                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">VISTALAN 2019/2020</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">VISTALAN 2021/2022</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer2"/>

                <section className="hp-s2">
                <Container fluid>
                        <Row className="hp-s2-r0" xs={1} md={2} xl={3}>
                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer3"/>

                <section className="hp-s3">
                <Container fluid>
                        <Row className="hp-s3-r0" xs={1} md={2} xl={3}>
                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card border className="card-history">
                                    <Card.Img variant="top" src={Soon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-history-text">Coming soon!</Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col> 
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
export default HistoryPage;