import React, { Component } from "react";
import { Container, Button, Row, Col, Card, Form} from "react-bootstrap";
import "../pages/ContactPage.css";
import Sharon from "../images/Sharon_Crew.jpg";
import Connor from "../images/Connor_Crew.jpg";
import Paul from "../images/Paul_Crew.jpg";


class ContactPage extends Component {
    render() {
        return(
            <React.Fragment>
                <section className="cp-s0" >
                    <Container fluid className="call-to-nothing">
                        <h1>VISTALAN contact</h1>
                        <Button></Button>
                    </Container>
                    <div className="spacer cp-s0-layer"/>
                </section>

                <section className="cp-s1">
                    <Container fluid>
                        <Row className="cp-s1-r0">
                            <h1>Ons team</h1>
                        </Row>
                        <Row className="cp-s1-r1" xs={1} md={2} xl={3}>
                            <Col xl>
                                <Card className="card-member">
                                    <Card.Img variant="top" src={Paul}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-member-text">Voorzitter</Card.Title>
                                    </Card.ImgOverlay>
                                    <Card.Body>
                                        <Card.Title>Paul Derix</Card.Title>
                                        <Card.Text>
                                            Vista Engels Docent. Samen met Connor de oprichter van de <span>VISTALAN</span>. Zijn doel was om zoveel mogelijk studenten samen te brengen en een onvergetelijke LAN-party te houden die ze samen met hun diplomering als een gekoesterde herinnering aan VISTA zien. Trotse organisator van de <span>VISTALAN</span>!
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card className="card-member">
                                    <Card.Img variant="top" src={Connor}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-member-text">Penningmeester</Card.Title>
                                    </Card.ImgOverlay>
                                    <Card.Body>
                                        <Card.Title>Connor Timmerman</Card.Title>
                                        <Card.Text>
                                            Een alumni van het VISTA College en de reden voor het bestaan de van de <span>VISTALAN</span>. Nadat Paul tijdens een open dag had geklaagd dat er geen LANs meer werden georganiseerd op het Vista, vroeg Connor: “Waarom doen wij het dan niet gewoon zelf?”. Daarnaast is hij ontwikkelaar van deze website.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl>
                                <Card className="card-member">
                                    <Card.Img variant="top" src={Sharon}/>
                                    <Card.ImgOverlay>
                                        <Card.Title className="card-member-text">Crew</Card.Title>
                                    </Card.ImgOverlay>
                                    <Card.Body>
                                        <Card.Title>Sharon Broer</Card.Title>
                                        <Card.Text>
                                            Afgestudeerd aan de opleiding Communicatie & Multimedia design en nu beginnend ontwerper. Creatief bezig zijn is mijn passie en dat deel ik graag met anderen in grafisch ontwerp, video/fotografie en tatoeëren. Als oud-mede stagiaire met Connor altijd fijn samengewerkt en ook nu weer voor deze mooie <span>VISTALAN</span>!
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer2"/>

                <section className="cp-s2">
                <Container fluid>
                        <Row className="cp-s2-r0">
                            <h1>Overige gegevens</h1>
                        </Row>
                        <Row className="cp-s2-r1">
                            <p><span>VISTALAN</span> is een team van studenten, alumni en docenten van de ICT-opleiding op het Vista College. Wij werken vrijwillig samen om een dit mooie evenement te organiseren. Na een succesvolle <span>VISTALAN</span> te hebben georganiseerd in februari 2020 (schooljaar 2019-2020) kozen wij ervoor om dit voortzetten voor de komende jaren. Wij houden ons bezig met alle aspecten die komen kijken bij het organiseren van dit evenement.</p>
                        </Row>
                    </Container>
                </section>

                <div className="spacer layer3"/>

                <section className="cp-s3">
                <Container fluid>
                        <Row className="cp-s3-r0">
                            <h1>Contact formulier</h1>
                        </Row>

                        <Row className="cp-s3-r1">
                            <Col >
                               
                            </Col>
                            
                            <Col xl={6}>
                                <Form>
                                    <Form.Group controlId="contactFrom.emailInput">
                                        <Form.Control type="email" placeholder="Jouw e-mailadres" required/>
                                    </Form.Group>
                                    
                                    <Form.Group controlId="contactForm.textArea">
                                        <Form.Control as="textarea" placeholder="Vraag op opmerking" required className="contact-text"/>
                                    </Form.Group>
                                    <Button className="contact-submit" type="submit">Stuur formulier</Button>
                                </Form>
                            </Col>

                            <Col >
                            </Col>
                            
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
export default ContactPage;